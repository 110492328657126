<script lang="ts" setup>
</script>

<template>
  <div flex flex-col items-center gap-8px pt-24px pb-16px>
    <h4 text-text-content-secondary fubex-content5-regular>
      Copyright © 2023 [ Company ] All rights reserved
    </h4>
    <div flex items-center justify-center gap-16px text-text-component-secondary-defaykt fubex-button3-semibold>
      <h3>Privacy Policy</h3>
      <h3>Terms & Conditions</h3>
      <h3>Imprint</h3>
    </div>
  </div>
</template>
